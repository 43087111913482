import React from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../components/templates/BaseLayout';
import SEO from '../components/atoms/utility/seo';
import PartnershipSignup from '../components/molecules/PartnershipSignup';
import DownloadAppCTA from '../components/atoms/callouts/DownloadAppCTA';
import PrimaryHeader from '../components/atoms/typography/PrimaryHeader';
import SubHeader from '../components/atoms/typography/SubHeader';
import Spacer from '../components/atoms/utility/Spacer';
import Headline from '../components/atoms/typography/Headline';
import FooterCallout from '../components/atoms/callouts/FooterCallout';
import RocketIcon from '../components/atoms/icons/RocketIcon';
import ChartBarIcon from '../components/atoms/icons/ChartBarIcon';
import ElectricBoltIcon from '../components/atoms/icons/ElectricBoltIcon';
import GiftIcon from '../components/atoms/icons/GiftIcon';
import StarIcon from '../components/atoms/icons/StarIcon';
import MicrophoneIcon from '../components/atoms/icons/MicrophoneIcon';
import CirclePlusIcon from '../components/atoms/icons/CirclePlusIcon';
import MapPinIcon from '../components/atoms/icons/MapPinIcon';
import ChatBubbleIcon from '../components/atoms/icons/ChatBubbleIcon';

export default function PartnershipPage({ data: { heroImg, mobileHeroImg } }) {
  const iconStyles = 'h-10 text-center w-full';

  const partnershipTraits = [
    {
      headerText: 'Grow Your Crowd',
      bodyText: () => (
        <p>
          <span className='font-bold'>Grow your audience</span> by
          leveraging the local communities on flockx.
        </p>
      ),
      icon: (
        <RocketIcon className={'h-14 lg:h-10 text-secondary-orange'} />
      )
    },
    {
      headerText: 'Drive Engagement',
      bodyText: () => (
        <p>
          <span className='font-bold'>Deliver quality content</span>{' '}
          to your followers to build engagement for free.
        </p>
      ),
      icon: (
        <ChartBarIcon className={'h-14 lg:h-10 text-secondary-green'} />
      )
    },
    {
      headerText: 'Gift Collectables',
      bodyText: () => (
        <p>
          <span className='font-bold'>Gift collectables</span> to your
          audience and find new ways to connect.
        </p>
      ),
      icon: <GiftIcon className={'h-14 lg:h-10 text-secondary-blue'} />
    },
    {
      headerText: 'Promote Your Events',
      bodyText: () => (
        <p>
          <span className='font-bold'>Share and promote</span> your
          events in real-time to grow your audience.
        </p>
      ),
      icon: <ElectricBoltIcon className={'h-14 lg:h-10 text-default'} />
    }
  ];

  const rewards = [
    {
      name: 'Request an A-Board',
      icon: <StarIcon className={iconStyles + ' text-default'} />
    },
    {
      name: 'Post on Socials',
      icon: (
        <MicrophoneIcon
          className={iconStyles + ' text-secondary-orange'}
        />
      )
    },
    {
      name: 'Submit Events',
      icon: (
        <MapPinIcon className={iconStyles + ' text-secondary-blue'} />
      )
    },
    {
      name: 'Refer Others',
      icon: <ChatBubbleIcon className={iconStyles + ' text-highlight'} />
    },
    {
      name: 'And more...',
      icon: <CirclePlusIcon className={iconStyles + ' text-default'} />
    }
  ];

  return (
    <BaseLayout
      heroContent={PartnershipHeroContent}
      mobileHeroImg={mobileHeroImg}
      heroImg={heroImg}
      heroAlt='A group of happy people in a crowd cheering at a music event.'
      footerHero={FooterHero}
    >
      <SEO
        title='Join Our Partnership in Real-Time for an Impact'
        keywords={[
          'mobile app',
          'influencer',
          'community',
          'exploration',
          'experience',
          'inspiration',
          'flockx',
          'partnership',
          'musicians',
          'live music'
        ]}
        description={
          'Join the flockx Community App Partnership Program and make a real-time impact. Connect with like-minded individuals, participate in trending activities, and support each other\'s growth in a supportive community.'
        }
      />
      <div>
        <Spacer sizeY={1} className='block xl:block' />
        <Spacer className='block sm:hidden' />
        <div id='community-leader-traits' className='relative'>
          <div className='text-center px-5'>
            <h3 className='text-3xl md:text-5xl lg:text-7xl text-secondary-blue mb-6 lg:mb-10'>
              Partner With Us!
            </h3>
            <p className='text-xl md:text-2xl lg:text-3xl max-w-5xl mx-auto mb-8 xl:leading-[1.3]'>
              <span className='text-highlight'>flockx</span> is
              looking for event planners, karaoke hosts, public
              speakers, yoga instructors, bartenders, DJ's,
              stand-up comedians or{' '}
              <span className='text-highlight'>
                                community leaders
                            </span>{' '}
              who want to grow their audiences organically.
            </p>
          </div>
        </div>
        <Spacer className='block sm:hidden' />
        <Spacer />
        <Spacer className='block sm:hidden' />
        <div id='partnership-traits' className='relative'>
          <div className='text-center px-5'>
            <h3 className='text-3xl md:text-5xl lg:text-7xl text-secondary-orange mb-16 lg:mb-20 xl:mb-32'>
              What's In It For Me?
            </h3>
            <ul className='text-xl grid grid-cols-1 lg:grid-cols-2 gap-x-24 gap-y-24'>
              {partnershipTraits.map((traits, index) => {
                const { icon, headerText, bodyText } = traits;
                return (
                  <li key={index} className='mb-6 last:mb-0'>
                    <div className='lg:flex gap-12'>
                      <div className='flex items-center justify-center lg:items-start lg:justify-start mb-8 lg:mb-0'>
                        {icon}
                      </div>
                      <div className='flex items-center justify-center lg:items-start lg:justify-start flex-col'>
                        <h3 className=' text-2xl lg:text-[32px] mb-4'>
                          {headerText}
                        </h3>
                        <div className='text-center lg:text-left text-lg lg:text-xl'>
                          {bodyText()}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <Spacer className='block sm:hidden' />
        <Spacer />
        <Spacer className='block sm:hidden' />
        <div className='text-center'>
          <Headline
            spanText='Get Rewards'
            spanColor='#7593FF'
            className='mb-6 lg:mb-10 lg:!text-6xl'
          />
          <p className='text-xl md:text-2xl lg:text-3xl max-w-2xl mx-auto mb-12 md:mb-16 lg:mb-20'>
            Looking to earn more while you lead?
          </p>
          <div
            className='grid grid-cols-2 sm:flex flex-col md:flex-row items-center justify-center gap-14 lg:gap-7 max-w-6xl mx-auto'>
            {rewards.map((item, index) => (
              <div key={index} className='w-full'>
                <div className=' p-4 rounded-full inline-flex h-full items-center justify-center'>
                  {item.icon}
                </div>
                <div className='text-grey-100 text-base lg:text-xl min-h-[50px] mt-4'>
                  {item.name}
                </div>
              </div>
            ))}
          </div>
        </div>
        <Spacer />
        <Spacer className='block sm:hidden' />
      </div>
    </BaseLayout>
  );
}

function PartnershipHeroContent() {
  return (
    <div className='-mt-[15%] sm:mt-[5%] lg:mt-0'>
      <div className='text-center sm:text-left sm:mt-6 lg:mt-0'>
        <div className='mb-10 md:mb-0'>
          <PrimaryHeader
            headerText='Turn Your Passion Into Profit'
            className='text-5xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-[72px] leading-[1.3] md:leading-[1.4] xl:leading-[1.3] mb-2 sm:mb-4 lg:mb-4 text-white capitalize max-w-[330px] sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-2xl mx-auto w-full lg:mr-0 sm:ml-0 lg:ml-0 font-light'
          />
          <SubHeader
            headerText='Join our partnership program and earn rewards'
            className='mb-4 md:mb-4 lg:mb-8 text-white mx-auto font-light max-w-[240px] sm:max-w-sm lg:max-w-md sm:mx-0'
          />
          <DownloadAppCTA containerStyles='justify-center sm:justify-start' id='partnership' />
          <p className='block sm:pt-0 font-light sm:text-lg md:text-xl lg:text-2xl text-white'>
            Available in the US
          </p>
        </div>
      </div>
    </div>
  );
}

function FooterHero() {
  return (
    <>
      <div className='relative w-full md:h-[540px] lg:h-[800px] flex items-center justify-center'>
        <div className='hidden md:block absolute h-full w-full'>
          <FooterCallout />
        </div>
        <PartnershipSignup />
      </div>
      <div className=''>
        <Spacer className='block sm:hidden' />
        <Spacer className='block sm:hidden' />
      </div>
    </>
  );
}

export const query = graphql`
    query PartnershipPageQuery {
        heroImg: file(name: { eq: "partnership-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        mobileHeroImg: file(name: { eq: "mobile-partnership-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;
